<template>
  Total Cost per Unit
  <span
    v-tooltip="'Ad spend / (Total Borrows + Total Orders). This tells you how much is your total cost per book, either sold or borrowed.'"
    class="pi pi-info-circle mx-1"
  />
</template>

<script>
export default {};
</script>
